import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useTicketList } from '@/api/exhibitionManage'
import { parseTime } from '@/utils/index'
export default () => {
  // 引入路由实例
  const router = useRouter()
  // 引入组件
  const loading = ref(false) // 列表动画加载
  const state = reactive({
    queryForm: { mobile: '', pageNum: 1, pageSize: 20, status: '' },
    total: 0,
    list: [],
    layout: 'prev, pager, next',
    typeOptions: [
      {
        label: '全部',
        value: '',
      },
      {
        label: '未开始',
        value: '',
      },
      {
        label: '可使用',
        value: '',
      },
      {
        label: '已使用',
        value: '',
      },
      {
        label: '已关闭',
        value: '',
      },
    ],
  })

  // 获取初始化数据
  const getData = async () => {
    loading.value = true
    const { data } = await useTicketList(state.queryForm)
    console.log(data, '1123123')
    state.list = data.data
    state.total = data.total
    setTimeout(() => {
      loading.value = false
    }, 500)
  }
  // 筛选时间
  const filterTime = (val) => {
    return parseTime(val)
  }
  // 下拉事件
  const handleChangeSelect = (e) => {
    state.queryForm.status = e
  }
  // 查询
  const handleQueryData = () => {
    console.log('查询11111111111111')
    state.queryForm.pageNum = 1
    getData()
  }
  // 条数
  const handleSizeChange = (pageSize) => {
    state.queryForm.pageSize = pageSize
    getData()
  }
  // 页码
  const handleCurrentChange = (pageNum) => {
    state.queryForm.pageNum = pageNum
    getData()
  }
  // 跳转订单详情
  const handleJumpOrderDetail = (row) => {
    console.log('row=====', row)
    router.push({
      path: '/exhibitionManage/orderDetail',
      orderNo: row.orderNo,
    })
  }
  return {
    state,
    getData,
    handleCurrentChange,
    handleJumpOrderDetail,
    handleQueryData,
    handleChangeSelect,
    filterTime,
    loading,
  }
}
